import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { useCallback } from "react";
import Helmet from "react-helmet-async";
import { Link, useParams } from "react-router-dom";

import ContentContainer from "components/Common/Layout/ContentContainer";
import LoadingOverlay from "components/Common/LoadingOverlay";
import PostList from "pages/Blog/PostList";
import ListPaginationControls from "pages/Common/ListPaginationControls";
import Error404 from "pages/Error404/Async";

import * as sortConstants from "constants/sort";
import loadPostsList from "sagas/pagination/lists/loadPostsList";
import {
  blogTitleTemplate,
  BLOG_BASE_PATH,
  generateBlogCategoryPath,
} from "utils/blog";
import { generateMetaArray } from "utils/meta";
import htmlDecode from "utils/misc/htmlDecode";

import useList from "hooks/useList";
import useLoadListEffect from "hooks/useLoadListEffect";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

import "styles/css/blog.css";

const baseStyles = {
  wrapper: {
    background: "#fff",
    ...gStyles.fontRegular,
  },
  inner: {
    margin: "3rem auto 3rem",
    maxWidth: "80rem",
  },
  backButtonContainer: {
    marginBottom: "1rem",
  },
  backButtonLink: {},
  backButtonIcon: {
    verticalAlign: "middle",
    fontSize: "0.6em",
    display: "inline-block",
  },
  title: {
    ...gStyles.avalonSemiBold,
    fontSize: "2em",
  },
  byline: {
    ...gStyles.fontMediumItalic,
    marginBottom: "2em",
  },
};

function BlogCategoryView(props) {
  const { slug } = useParams();

  const { styles } = useStyles(baseStyles, props);
  const listKey = `blog/category/${slug}`;
  const { list, is404, ids, currentPage } = useList(listKey);
  const paginationUri = useCallback(
    ({ pageNumber }) => `${generateBlogCategoryPath(slug)}?page=${pageNumber}`,
    [slug]
  );

  useLoadListEffect(listKey, {
    sort: sortConstants.SORT_ORDER_RECENT,
    list_type: "posts_filtered",
    loadListAction: loadPostsList,
    entity_type: "post",
    apiProps: {
      category: slug,
    },
    pageSize: 24,
  });

  if (!list) {
    return null;
  }

  if (list.get("loading")) {
    return <LoadingOverlay key="blogCategoryViewLoadingOverlay" />;
  }
  if (is404 || !list.getIn(["additional", "category"])) {
    return <Error404 />;
  }

  return (
    <div className={css(styles.wrapper)} id="blog-single-category">
      <Helmet
        titleTemplate={blogTitleTemplate}
        title={`${list.getIn(["additional", "category", "name"])}`}
        meta={generateMetaArray({
          title: `${list.getIn([
            "additional",
            "category",
            "name",
          ])} | Articles on Podchaser`,
          description: list.getIn(["additional", "category", "description"]),
        })}
      />
      <ContentContainer>
        <div className={css(styles.inner)}>
          <div className={css(styles.backButtonContainer)}>
            <Link
              data-id="blog-back-to-articles-index"
              to={BLOG_BASE_PATH}
              anchor
              className={css(styles.backButtonLink)}
            >
              <span className={css(styles.backButtonIcon)}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>{" "}
              Back To Articles
            </Link>
          </div>
          <h1 className={css(styles.title)}>
            {`${list.getIn(["additional", "category", "name"])} Articles`}{" "}
            {currentPage > 1 && ` - Page ${currentPage}`}
          </h1>
          {list.getIn(["additional", "category", "description"]) && (
            <div className={css(styles.byline)}>
              {htmlDecode(
                list.getIn(["additional", "category", "description"])
              )}
            </div>
          )}
          <PostList ids={ids} />
          <ListPaginationControls
            listKey={listKey}
            generateUri={paginationUri}
          />
        </div>
      </ContentContainer>
    </div>
  );
}

export default BlogCategoryView;
