import { List, Map } from "immutable";

import {
  selectSpecificEpisode,
  selectSpecificPodcast,
} from "selectors/podcast";
import { selectSpecificReview } from "selectors/reviews";
import { getSort } from "utils/pagination";

export const selectListKeys = (state) =>
  state.pagination.get("lists").keySeq().toArray();

export const selectList = (state, props) =>
  state.pagination &&
  props &&
  props.key &&
  state.pagination.getIn(["lists", props.key]);

export const selectListOptions = (state, passedKey) => {
  const key = passedKey && passedKey.key ? passedKey.key : passedKey;

  return (
    state &&
    state.pagination &&
    key &&
    state.pagination.getIn(["lists", key, "options"])
  );
};

export const selectRawListSort = (state, passedKey) => {
  const key = passedKey && passedKey.key ? passedKey.key : passedKey;

  return (
    state &&
    state.pagination &&
    key &&
    state.pagination.getIn(["lists", key, "sort"])
  );
};

export const selectListSort = (state, key) => {
  const sort = selectRawListSort(state, key);
  const options = selectListOptions(state, key);

  return getSort(sort, options);
};

export const selectListGroups = (state, key) =>
  state &&
  state.pagination &&
  key &&
  state.pagination.getIn(["lists", key, "groups"]);

export const selectListSortDirection = (state, key) =>
  state &&
  state.pagination &&
  key &&
  state.pagination.getIn(["lists", key, "sort_direction"]);

export const selectListEntityType = (state, key) =>
  state &&
  state.pagination &&
  key &&
  state.pagination.getIn(["lists", key, "entity_type"]);

export const selectListIds = (state, key) =>
  (state &&
    state.pagination &&
    key &&
    state.pagination.getIn([
      "lists",
      key,
      "idsBySort",
      selectListSort(state, key),
    ])) ||
  List();

export const selectListLoading = (state, props) =>
  state &&
  state.pagination &&
  props &&
  props.key &&
  state.pagination.getIn(["lists", props.key, "loading"]);
export const selectListLoaded = (state, props) =>
  state &&
  state.pagination &&
  props &&
  props.key &&
  state.pagination.getIn(["lists", props.key, "loaded"]);
export const selectListHasMore = (state, props) =>
  state &&
  state.pagination &&
  props &&
  props.key &&
  state.pagination.getIn(["lists", props.key, "hasMore"]);
export const selectListTotal = (state, props) =>
  state &&
  state.pagination &&
  props &&
  props.key &&
  state.pagination.getIn(["lists", props.key, "total"]);
export const selectListAdditional = (state, props) =>
  state &&
  state.pagination &&
  props &&
  props.key &&
  state.pagination.getIn(["lists", props.key, "additional"]);

export const selectFilters = (state, props) =>
  (state &&
    state.pagination &&
    props &&
    props.key &&
    state.pagination.getIn(["lists", props.key, "filters"])) ||
  Map();

export const selectSearchTerm = (state, props) =>
  state &&
  state.pagination &&
  props &&
  props.key &&
  state.pagination.getIn(["lists", props.key, "searchTerm"]);

export const selectFilterTotalLoaded = (state, props) =>
  state &&
  state.pagination &&
  props &&
  props.key &&
  state.pagination.getIn(["lists", props.key, "loadingTotalCount"]);

export const selectAllFilters = (state, entity_type) =>
  state &&
  state.pagination &&
  state.pagination.getIn(["allFiltersByType", entity_type]);

export const selectDenormalisedList = (state, key) => {
  switch (selectListEntityType(state, key)) {
    case "podcast": {
      return selectListIds(state, key).map((id) =>
        selectSpecificPodcast(state, id)
      );
    }
    case "episode": {
      return selectListIds(state, key).map((id) =>
        selectSpecificEpisode(state, id)
      );
    }
    case "review": {
      return selectListIds(state, key).map((id) =>
        selectSpecificReview(state, id)
      );
    }
  }
};
